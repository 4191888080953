<template>
  <div class="page-box box">
    <!-- top -->
    <div class="top-box box">
      <!-- banner -->
      <!-- <div class="banner-box box">
        
        <img src="@/assets/img/school-banner.png" alt="">
      </div> -->

      <div class="banner-box box">
        <!-- v-show="detailData.videoUrl" -->
        <div
          class="play-btn"
          @click="playVideo"
        >
          <van-icon
            class="play-icon"
            name="play"
          />
          预约试听
        </div>
        <van-swipe
          class="swiper"
          :autoplay="4500"
          @change="onChange"
        >
          <template v-for="(item,index) in detailData.imgUrls || []">
            <van-swipe-item
              :key="index"
              class="swiper-item"
            >
              <van-image :src="item"></van-image>
            </van-swipe-item>
          </template>
          <template #indicator>
            <div class="custom-indicator box">
              <template v-for="(item,index) in detailData.imgUrls || []">
                <div
                  :key="index"
                  class="swiper-indicator"
                  :class="{'active-indicator': index === current}"
                >
                </div>
              </template>
            </div>
          </template>
        </van-swipe>
      </div>

      <div class="text-a">
        <div
          v-show="detailData.activity == 1"
          class="tag-a tag"
        >
          机构活动
        </div>
        <!-- <div class="tag-b tag">
          门店活动
        </div> -->
        <div
          class="text"
          :class="{'text-indent': detailData.activity == 1}"
        >
          {{ detailData.courseName || '--' }}
        </div>
      </div>

      <div class="text-b">
        <div class="price-a">
          ￥{{ parseFloat(detailData.fee || 0).toFixed(2) }}
        </div>
        <div class="price-b">
          ￥{{ parseFloat(detailData.fee || 0).toFixed(2) }}
        </div>
      </div>

      <div class="tag-box box">
        <div
          v-if="detailData.classifyName"
          class="tag-item"
        >
          {{ detailData.classifyName || '--' }}
        </div>
        <div
          v-if="detailData.startAge && detailData.endAge"
          class="tag-item"
        >
          {{ detailData.startAge + '—' + detailData.endAge }}岁
        </div>
        <div
          v-if="detailData.classesTypeName"
          class="tag-item"
        >
          {{ detailData.classesTypeName }}
        </div>
        <div
          v-if="detailData.lesson"
          class="tag-item"
        >
          {{ detailData.lesson || '--' }}节
        </div>
        <div
          class="tag-item"
        >
          {{ detailData.lengthTime || '0' }}分钟
        </div>
        <div
          v-if="detailData.classifyName"
          class="tag-item"
        >
          {{ detailData.classifyName || '--' }}
        </div>
      </div>
    </div>

    <!--  -->
    <div class="bottom-box box">
      <!-- tab -->
      <div class="tab-box">
        <van-tabs
          v-model="activeName"
          calss="tab-out-box"
          swipeable
          color="#51B464"
          title-inactive-color="#262626"
          title-active-color="#51B464"
          @change="tabChange"
        >
          <template v-for="item in tabList">
            <van-tab
              :key="item.name"
              class="van-tab-box"
              :title="item.title"
              :name="item.name"
            >
            </van-tab>
          </template>
        </van-tabs>
      </div>

      <div class="border-x"></div>

      <div class="list-box box">
        <!-- 课程列表 -->
        <div class="tab-in-box tab-a box">
          <div
            id="view-a"
            class="title"
          >
            课程介绍
          </div>
          <div
            class="content"
            v-html="detailData.introduce || ''"
          >
            <!-- <ul>
              <li>适合人群：想提分，保分的学员。</li>
              <li>班型：托管班、保分班、短期班、长期班</li>
              <li>上课时间：随时</li>
              <li>总课时数：短期按月，长期按年</li>
              <li>学习目标：说一口地道的英语，熟练用英语</li>
              <li>口语进行交流</li>
              <li>免费试听课：有</li>
              <li>每次课时长：1小时</li>
              <li>上课人数：小班/一对一/多</li>
            </ul> -->
          </div>
        </div>

        <!-- 其他分校 -->
        <div class="tab-in-box tab-c box">
          <div
            id="view-b"
            class="title"
          >
            师资特色
          </div>
          <div
            class="content"
            v-html="detailData.characteristics || ''"
          ></div>
          <!-- <div class="img-box">
            <img
              src="@/assets/img/test-a.png"
              alt=""
            >
          </div> -->
        </div>
        <!-- 机构简介 -->

        <div class="tab-in-box tab-c box">
          <div
            id="view-c"
            class="title"
          >
            课程亮点
          </div>
          <div
            class="content"
            v-html="detailData.highlights || ''"
          ></div>
        </div>
        <!-- imgDesc -->
        <div
          v-if="detailData.imgDesc"
          class="tab-in-box tab-c box"
        >
          <div
            class="title"
          >
            图文详情
          </div>
          <div
            v-for="(item, index) in detailData.imgDescs || []"
            :key="index"
            class="img-box"
          >
            <van-image
              :src="item"
            ></van-image>
          </div>
        </div>
      </div>

      <!-- 支付 -->
      <div class="pay-box">
        <div class="text">
          <span>￥</span>
          {{ parseFloat(detailData.fee || 0).toFixed(2) }}
        </div>
        <div
          class="btn-box"
        >
          <div
            class="btn-b"
            @click="fqPayBtn"
          >
            分期支付
          </div>
          <div
            class="btn-a"
            @click="payBtn"
          >
            全款支付
          </div>
        </div>
      </div>
    </div>

    <!-- code 码 -->
    <van-overlay
      style="background: rgba(0,0,0,0.3)"
      :show="show"
      @click="show = false"
    >
      <div
        class="wrapper"
        @click.stop="show = false"
      >
        <div
          class="block"
          @click.stop
        >
          <template v-if="pageStatus === 'reg'">
            <div class="text-a">
              请先注册账号
            </div>
            <van-field
              v-model="reg.mobile"
              class="text-c"
              maxlength="11"
              type="number"
              placeholder="输入手机号"
              label="手机号"
            />
            <van-field
              v-model="reg.code"
              class="text-c"
              maxlength="6"
              placeholder="输入验证码"
              label="验证码"
            >
              <template #button>
                <span
                  class="hr-btn"
                  @click="sendCode"
                >{{ codeStatus ? '发送验证码' : codeTime }}</span>
              </template>
            </van-field>
            <div class="box user-doc">
              <van-checkbox v-model="userRadio">
                <span
                  class="user-in-doc"
                  @click.stop="goUserDoc"
                >青童季用户注册协议</span>
              </van-checkbox>
            </div>
            <van-button
              class="code-btn"
              color="#51B464"
              round
              type="info"
              @click="codeSave"
            >
              提交
            </van-button>
          </template>
          <!-- <template v-if="pageStatus === 'regSuccess'">
          </template> -->
        </div>
      </div>
    </van-overlay>

    <!-- 视频播放 -->
    <popup-play-video
      v-if="detailData.videoUrl"
      v-model="showAndVideo"
      :video-url="detailData.videoUrl || 'http://vjs.zencdn.net/v/oceans.mp4'"
    />
    <!-- v-if="showIosVideo" -->
    <video
      v-show="detailData.videoUrl"
      id="corseVideo"
      :src="detailData.videoUrl || 'http://vjs.zencdn.net/v/oceans.mp4'"
    >
      <source
        :src="detailData.videoUrl || 'http://vjs.zencdn.net/v/oceans.mp4'"
        type="video/mp4"
      >
      您的浏览器不支持 video 标签。
    </video>
    <!-- 预约试听 -->
    <van-action-sheet
      v-model="showAppointment"
      title="预约试听"
    >
      <div
        class="box appintment-box"
      >
        <van-field
          v-model="userName"
          label="姓名"
          placeholder="输入姓名"
        />
        <van-field
          v-model="mobile"
          type="number"
          label="联系电话"
          placeholder="输入联系电话"
        />
        <van-button
          class="submit-btn"
          color="#51B464"
          round
          type="info"
          @click="submitAppointment"
        >
          提交
        </van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
// import SchoolItem from "@/components/list-item/school-item.vue"
import popupPlayVideo from "@/components/common/popupVideo.vue"
export default {
  name: 'SchoolIndex',
  components: {
    popupPlayVideo
  },
  data () {
    return {
      userName: '',
      mobile: '',
      text: '',
      show: false,
      userRadio: '',
      showAppointment: false,
      showAndVideo: false,
      showIosVideo: false,
      pageStatus: 'reg',
      tabList: [
        {title: '课程介绍', name: 'a'},
        {title: '师资特色', name: 'b'},
        {title: '课程亮点', name: 'c'}
      ],
      activeName: 'a',
      detailData: {},
      current: 0,
      reg: {
        mobile: '',
        code: ''
      },
      codeTime: 60,
      codeStatus: true,
      faceData: {},
    }
  },
  computed: {
    isAndroid () {
      let u = navigator.userAgent
      return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
    }
  },
  created () {
    this.getData()
  },
  methods: {
    goUserDoc () {
      window.open('https://img.tjerong.cn/base/reg.html')
    },
    async submitAppointment () {
      // appointment
      let data = {
        courseNo: this.$route.query.id,
        mobile: this.mobile,
        userName: this.userName
      }
      if (data.mobile && this.userName) {
        this.$toast.loading({
          message: '加载中',
          forbidClick: true,
          duration: 0
        });
        let res = await this.$http.homeApi.appointment(data)
        if (res.code === 200) {
          this.$toast.clear()
          this.showAppointment = false
          this.$toast.success('预约成功')
        }
      } else {
        this.$toast('请完善信息')
      }
    },
    async getData () {
      let data = {
        id: this.$route.query.id || ''
      }
      let res = await this.$http.homeApi.courseDetail(data)
      if (res.code === 200) {
        this.detailData = res.msg || {}
      }
    },
    onChange(index) {
      this.current = index;
    },
    tabChange (e) {
      document.getElementById('view-'+e).scrollIntoView()
    },
    fullScreen (ele) {
      // var ele = document.documentElement
      if (ele.requestFullscreen) {
        ele.requestFullscreen()
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen()
      } else if (ele.webkitRequestFullScreen) {
        ele.webkitRequestFullScreen()
      }
    },
    exitFullscreen () {
      var de = document
      if (de.exitFullscreen) {
        de.exitFullscreen()
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen()
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen()
      }
    },
    playVideo (url) {
      // if (this.isAndroid) {
      //   this.showPopupVideo = true
      // } else {
      //   this.showIosVideo = true
      //   this.$nextTick(() => {
      //     let el = document.getElementById('corseVideo')
      //     this.fullScreen(el)
      //     el.play()
      //   })
      // }
      this.showAppointment = true
    },
    // 全款支付
    async payBtn () {
      if (this.detailData.fee == '0') {
        this.$toast('该课程不支持全款支付，请选择其他支付方式')
        return
      }
      // 支付按钮状态
      this.btnStatus = 'pay'
      window.localStorage.setItem('courseDetail', JSON.stringify(this.detailData))
      let res = await this.$http.orderApi.checkFullOrder({})
      if (res.code === 200) {
        console.log(res, '全款')
        window.localStorage.setItem('payCheckInfo', JSON.stringify(res.msg || {}))
        if (res.msg.reg == '0') {
          this.show = true
        } else {
          this.$router.push('/school/coursePay?id=' + this.$route.query.id)
        }
      }
    },
    // 分期支付
    async fqPayBtn () {
      this.$toast.loading({
        message: '加载中',
        forbidClick: true
      });
      // 分期支付按钮状态保存
      this.btnStatus = 'fq'
      let res = await this.$http.orderApi.orderCheckV2({id: this.$route.query.id})
      console.log(res, 'res')
      if (res.code === 200) {
        this.$toast.clear()
        this.checkInfo = res.msg || {}
        window.localStorage.setItem('userCheckInfo', JSON.stringify(this.checkInfo))
        this.jumpPage()
      }
    },
    // 跳转页面
    async jumpPage () {
      if (this.checkInfo.reg === 1) {
        this.show = true
      } else if (this.checkInfo.face === 11) {
      } else {
        // 跳转完善信息
        window.localStorage.setItem('courseDetail', JSON.stringify(this.detailData))
        this.$router.push('/order/realAuth?id=' + this.$route.query.id)
        // this.$router.push('/school/basicInfo?id=' + this.$route.query.id)
      }
    },
    async codeSave () {
      if (!this.userRadio) {
        this.$toast('请先同意注册协议')
        return
      }
      if (this.reg.mobile && this.reg.mobile.length === 11 && this.reg.code) {
        this.$toast.loading({
          message: '加载中',
          forbidClick: true
        });
        let res = await this.$http.userApi.mobileBind(this.reg)
        if (res.code === 200) {
          this.$toast.clear()
          this.$toast.success('注册成功，正在为您跳转')
          setTimeout(() => {
            if (this.btnStatus === 'fq') {
              this.$router.push('/order/realAuth?id=' + this.$route.query.id)
            } else {
              this.$router.push('/school/coursePay?id=' + this.$route.query.id)
            }
          }, 1500);
        }
      } else {
        this.$toast('请完善信息')
      }
    },
    sendCodeTime () {
      if (this.codeTime > 0) {
        this.codeTime --
        setTimeout(() => {
          this.sendCodeTime()
        }, 1000);
      } else {
        this.codeTime = 60
        this.codeStatus = true
      }
    },
    async sendCode () {
      if (!this.codeStatus) {
       return   
      }
      if (this.reg.mobile && this.reg.mobile.length === 11) {
        let data = {mobile: this.reg.mobile}
        let res = await this.$http.userApi.sendCode(data)
        if (res.code === 200) {
          this.$toast.success('发送成功')
          this.codeStatus = false
          this.sendCodeTime()
        }
      } else {
        this.$toast('请完善手机号')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  background: #F5F5F5;
  padding-bottom: 62px;
  .top-box {
    background: #fff;
    padding-bottom: 15px;
    .banner-box {
      // padding: 10px 0;
      width: 100%;
      height: 205px;
      margin-bottom: 8px;
      .play-btn {
        line-height: 38px;
        width: 122px;
        text-align: center;
        background: rgba(0,0,0,0.7);
        color: #fff;
        position: absolute;
        left: 50%;
        margin-left: -61px;
        top: 50%;
        margin-top: -19px;
        border-radius: 19px;
        font-size: 14px;
        z-index: 9;
        .play-icon {
          margin-right: 6px;
          top: 4px;
          font-size: 18px;
          position: relative;
        }
      }
      .swiper {
        height: 100%;
        width: 100%;
        position: relative;
        text-align: center;
        line-height: 215px;
        .swiper-item {
          img {
            width: 100%;
            height: 100%;
            position: relative;
          }
        }
        .custom-indicator {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          text-align: center;
          height: 20px;
          line-height: 14px;
          cursor: pointer;
          .swiper-indicator {
            width: 10px;
            height: 3px;
            display: inline-block;
            margin: 0 3px;
            background: rgb(190,190,197);
            border-radius: 1.5px;
          }
          .active-indicator {
            background: #282734;
          }
        }
      }
      img {
        display: block;
        width: 345px;
        height: 184px;
        margin: 0 auto;
        border-radius: 7px;
      }
    }
    .text-a {
      margin-top: 6px;
      overflow: hidden;
      font-size: 14px;
      line-height: 14px;
      padding: 0 15px;
      position: relative;
      .tag {
        // display: inline-block;
        float: left;
        line-height: 16px;
        padding: 0 6px;
        font-size: x-small;
        font-size: 10px;
        color:#fff;
        border-radius: 8px;
        // margin-bottom: 2px;
        position: absolute;
      }
      .tag-a {
        left: 15px;
        top: 3px;
        background: linear-gradient(90deg, #FF953F, #FB703B);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      }
      .tag-b {
        background: linear-gradient(-90deg, #6A42F5, #A084FF);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      }
      .text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        
        font-size: 15px;
        line-height: 20px;
      }
      .text-indent {
        text-indent: 66px;
      }
    }
    .text-b {
      padding: 0 15px;
      margin: 4px 0;
      .price-a {
        color: #D5423E;
        font-size: 16px;
        line-height: 28px;
        margin-right: 4px;
        float: left;
      }
      .price-b {
        color: #B9B9B9;
        font-size: 10px;
        font-size: x-small;
        line-height: 24px;
        text-decoration: line-through;
        float: left;
        margin-top: 6px;
      }
    }
    .tag-box {
      padding: 0 15px;
      .tag-item {
        line-height: 16px;
        background: #E8F7EF;
        color: #51B464;
        padding: 0 6px;
        font-size: 11px;
        font-size: x-small;
        border-radius:2px;
        float: left;
        margin-right: 8px;
      }
    }
  }

  // bottom
  .bottom-box {
    margin-top: 6px;
    .tab-in-box {
      padding: 15px;
      background: #fff;
      .title {
        line-height: 24px;
        font-size: 15px;
      }
      .content {
        font-size: 13px;
        color: #8a8a8a;
        line-height: 24px;
        // padding-left: 16px;
        // text-indent: 26px;
        ul {
          list-style: disc;
          margin-top: 8px;
        }
      }
    }
    .tab-a {
      padding: 15px;
    }
    .tab-b, .tab-c {
      margin-top: 6px;
      .img-box {
        margin-top: 10px;
        img {
          width: 100%;
          height: auto;
        }
        .van-image {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .border-x {
    width: 100%;
    height: 1px;
    margin-top: -1px;
    background: #d9d9d9;
    position: relative;
  }

  .pay-box {
    width: 100%;
    height: 55px;
    line-height: 55px;
    padding: 0 20px;
    position: fixed;
    bottom: 0;
    bottom: constant(safe-area-inset-bottom); ///兼容 IOS<11.2/
    bottom: env(safe-area-inset-bottom); ///兼容 IOS>11.2/
    left: 0;
    background: #fff;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
    .text {
      color: #D5423E;
      font-size: 22px;
      font-weight: bold;
      span {
        font-size: 12px;
        line-height: 55px;
      }
    }
    .btn-box {
      height: 40px;
      line-height: 40px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      position: absolute;
      right: 20px;
      top: 7.5px;
      .btn-a {
        width: 95px;
        background: #282734;
        border-radius: 19px 0px 0px 19px;
        float: right;
      }
      .btn-b {
        float: right;
        background: #51B464;
        width: 115px;
        border-radius: 0px 19px 19px 0px;
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .text-a {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .block {
      width: 294px;
      height: 270px;
      background: #fff;
      padding-top: 30px;
      text-align: center;
      border-radius: 5px;
    }
    .code-btn {
      width: 150px;
      height: 40px;
      font-size: 15px;
      margin-top: 36px;
    }
  }
  #corseVideo {
    opacity: 0;
    left: -2000px;
    position: absolute;
  }
  
  .appointment-box {
    padding-bottom: 30px;
    padding-bottom: constant(safe-area-inset-bottom); ///兼容 IOS<11.2/
    padding-bottom: env(safe-area-inset-bottom);
  }  
  .submit-btn {
    margin: 20px auto;
    display: block;
    width: 345px;
  }
  .user-doc {
    padding-left: 18px;
    padding-top: 10px;
    .user-in-doc {
      color: #0c64eb;
      cursor: pointer;
      margin-left: 4px;
    }
  }
}
</style>
