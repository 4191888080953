/*
 * @Author: huangzq
 * @Date: 2020-10-15 11:35:57
 * @Last Modified by: huangzq
 * @Last Modified time: 2020-10-15 17:19:31
 * @Desc: 弹窗播放视频组件
*/
<template>
  <van-popup
    closeable
    safe-area-inset-bottom
    class="video-box popup-play-video"
    v-bind="$attrs"
    v-on="listeners"
  >
    <video
      v-if="newVideoUrl"
      ref="videoRef"
      class="fullscreen-video"
      :src="newVideoUrl"
      :controls="controls"
      :tarindex="newVideoUrl"
      autoplay
      playsinline="true"
      webkit-playsinline="true"
    />
  </van-popup>
</template>

<script>
export default {
  name: 'PopupPlayVideo',
  props: {
    videoUrl: {
      type: String,
      default: ''
    },
    controls: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      newVideoUrl: ''
    }
  },

  computed: {
    listeners () {
      return {
        ...this.$listeners,
        opened: () => {
          this.opened()
        },
        closed: () => {
          this.closed()
        }
      }
    }
  },

  methods: {
    opened () {
      this.newVideoUrl = this.videoUrl
      this.$nextTick(() => {
        if (this.$refs.videoRef) this.$refs.videoRef.play()
      })
    },
    closed () {
      this.newVideoUrl = ''
      this.$nextTick(() => {
        if (this.$refs.videoRef) this.$refs.videoRef.pause()
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.popup-play-video {
  width: 100%;
  height: auto;
  background-color: #000;

  .fullscreen-video {
    width: 100%;
    max-height: 300px;
  }
}
</style>
